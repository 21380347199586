<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <div class="form-group-title"><h2>基本信息</h2></div>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="工号" prop="number">
                        <el-input type="text" v-model="form.number" size="small" maxlength="20" @blur="fieldTrim(form, 'number')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                     <el-form-item label="用户名" prop="userName">
                        <el-input type="text" v-model="form.userName" size="small" maxlength="50" @blur="fieldTrim(form, 'userName')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="form-group-title"><h2>个人信息</h2></div>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="姓名" prop="name">
                        <el-input type="text" v-model="form.name" size="small" maxlength="50" @blur="fieldTrim(form, 'name')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="性别" prop="sex">
                        <el-select v-model="form.sex" placeholder="性别" size="small" style="width:100%;">
                            <el-option label="男" value="1"></el-option>
                            <el-option label="女" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="手机号" prop="mobileNumber">
                        <el-input type="text" v-model="form.mobileNumber" size="small" @blur="fieldTrim(form, 'mobileNumber')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">&nbsp;</el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'DemoBaseAdd',
    data(){
        return {
            form: {
                number: '',
                name: '',
                userName: '',
                sex: '',
                mobileNumber: '',
            },
            rules: {
                number: [{required: true, message: '工号不能为空', trigger: 'blur'}],
                name: [{required: true, message: '姓名不能为空', trigger: 'blur'}],
                userName: [{required: true, message: '用户名不能为空', trigger: 'blur'}],
                sex: [{required: true, message: '性别不能为空', trigger: 'blur'}],
                mobileNumber: [
                    {required: true, message: '手机号不能为空', trigger: 'blur'},
                    {required: true, validator: this.checkMobileNumber, trigger: 'blur'}
                ],
            },
            submitting: false
        }
    },
    created() {
        
    },
    methods: {
        ...common,
        ...verify,
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    http.post(http.MOCK_BASE_API_URL + 'demo', this.form).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('添加成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
    }
};
</script>

<style scoped>
</style>